<template>
  <div class="contain_list" @click="NewsInfo(datas.onlyFlag, datas.navName)">
    <div class="pic">
      <img :src="datas.thumbnail" alt="" />
    </div>
    <div class="article">
      <div class="titleName">
        <img
          v-if="news"
          src="https://resource.duobaoyu.com.cn/website/new.png"
          alt=""
        />
        <span>{{ datas.title }}</span>
      </div>
      <div class="desc">
        {{ datas.summary }}
      </div>
      <div class="datetime">
        <img src="https://resource.duobaoyu.com.cn/website/clock.png" alt="" />
        <span>{{ datas.updateTime }}</span>
      </div>
      <div class="iconRight">
        <a-icon type="right" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewsItem",
  props: {
    datas: {
      type: Object,
      default: () => {},
    },
    news: {
      type: Boolean,
      default: true,
    },
    activeKey: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    NewsInfo(id, navName) {
      localStorage.setItem("activeKey", this.activeKey);
      this.$router.push({
        name: "NewsInfo",
        query: {
          id,
          navName,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.iconRight {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #fff;
  text-align: center;
  line-height: 40px;
  color: #ada9a9;
  position: absolute;
  right: 15px;
  top: 100px;
}

.contain_list {
  width: 100%;
  display: flex;
  cursor: pointer;
  margin-bottom: 30px;
  border-bottom: 1px solid #f0eeee;
  padding-bottom: 30px;
  &:hover {
    background: #f6f8fa;

    .pic > img {
      transform: scale(1.1);
    }
    .iconRight {
      background: #ff6a00;
      color: #fff;
    }
  }

  .pic {
    width: 412px;
    height: 252px;
    border-radius: 10px;
    overflow: hidden;

    img {
      width: 412px;
      height: 252px;
      transition: all 0.4s ease-in-out;
    }
  }

  .article {
    flex: 1;
    margin-left: 38px;
    padding-top: 20px;
    position: relative;

    .titleName {
      display: flex;
      align-items: center;

      img {
        width: 40px;
        height: 22px;
        margin-right: 10px;
      }

      span {
        font-size: 20px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: 700;
        color: #ff6a00;
      }
    }

    .desc {
      width: 622px;
      height: 50px;
      font-size: 18px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999;
      line-height: 25px;
      margin-top: 30px;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .datetime {
      position: absolute;
      bottom: 8px;
      display: flex;
      align-items: center;

      img {
        width: 18px;
        height: 18px;
      }

      span {
        font-size: 13px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #999;
        margin-left: 10px;
      }
    }
  }
}
</style>
