<template>
  <div class="news">
    <div class="bgone">
      <div class="bgone_tit">
        <div>
          <div><h1>企业动态</h1></div>
          <div>
            <p>公司动态、行业资讯等精彩内容</p>
          </div>
        </div>
      </div>
    </div>

    <!-- <div style="background: #f6f6f6; height: 60px">
      <div class="container align-items" style="padding-top: 20px">
        <a-breadcrumb separator=">">
          <a-breadcrumb-item>
            <img
              style="width: 10px; margin-bottom: 2px; margin-right: 5px"
              src="/mp/VirHome/homes.png"
              alt=""
            />
            <span class="span" @click="home">首页</span>
          </a-breadcrumb-item>
          <a-breadcrumb-item>
            <span class="span" @click="back">企业动态</span>
          </a-breadcrumb-item>
        </a-breadcrumb>
      </div>
    </div> -->

    <div class="News-tab" style="margin-top: 60px">
      <div class="container">
        <div class="News-tab-box">
          <div
            :class="[activeKey === index ? 'activeColor' : '']"
            v-for="(item, index) in tabs"
            :key="index"
            @click="changetabs(index)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>

    <div class="" style="padding-bottom: 60px">
      <div class="contWidth1440 marginAuto" style="padding-top: 60px">
        <NewsItem
          v-for="(item, index) in list"
          :key="index"
          :datas="item"
          :news="index === 0"
          :activeKey="activeKey"
        />
      </div>
    </div>

    <div class="jiantext" v-if="list.length > 0" style="margin-bottom: 120px">
      <a-pagination
        v-if="list.length > 0"
        v-model="page.pageNum"
        :total="total"
        :page-size.sync="page.pageSize"
        @change="onChange"
        show-quick-jumper
      />
    </div>

    <HomeFooterBanner></HomeFooterBanner>
  </div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2022/12/28 16:41
 * @version 1.0
 * @description
 */
import WOW from "wowjs";
import BannerImg from "@/components/BannerImg";
import NewsItem from "./components/NewsItem/index";
import { inquireTree, getDetailsList } from "@/api/HelpCenter";
import path from "path";
import HomeFooterBanner from "@/Layouts/components/FooterBanner/footerBanner/index.vue";

export default {
  name: "News",
  components: {
    BannerImg,
    NewsItem,
    HomeFooterBanner,
  },
  data() {
    return {
      activeKey: 0,
      tabs: [],
      list: [],
      indexId: 1,
      page: {
        pageNum: 1,
        pageSize: 5,
      },
      total: "",
    };
  },
  created() {
    this.getList();
    let activeKey = localStorage.getItem("activeKey");
    if (activeKey) this.activeKey = Number(activeKey);
  },
  mounted() {
    // new WOW.WOW().init()
    localStorage.removeItem("activeKey");
  },

  methods: {
    onChange(pageNumber) {
      document.documentElement.scrollTop = 0
      document.body.scrollTop = 0
      this.pageNum = pageNumber;
      this.getInfo();
    },
    home() {
      localStorage.removeItem("activeKey");
      this.$router.push({
        path: "/",
      });
    },
    back() {
      this.$router.push({ path: "/news" });
    },
    changetabs(index) {
      if (this.activeKey === index) return;
      this.activeKey = index;
      this.indexId = this.tabs[index].id;
      this.list = [];
      this.page.pageNum = 1;
      this.getInfo();
    },
    // tabs
    getList() {
      inquireTree(1).then((res) => {
        this.tabs = res.data;
        this.indexId = this.tabs[this.activeKey].id;
        this.getInfo();
      });
    },
    more() {
      this.page.pageNum++;
      this.getInfo();
    },
    // item
    getInfo() {
      getDetailsList(this.indexId, this.page).then((res) => {
        this.list = res.data.rows;
        this.total = res.data.total;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.bgone {
  background: url(../../assets/home/dd.png) no-repeat;
  height: 450px;
  width: 100%;
  background-size: 100% 100%;
  &_tit {
    width: 1440px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
  }
}
.news-card {
  padding: 23px;
}

.News-tab {
  width: 100%;
  height: 60px;
  line-height: 60px;

  &-box {
    font-size: 18px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #000;

    div {
      display: inline-block;
      margin-right: 60px;
      cursor: pointer;
      position: relative;
    }

    .activeColor {
      color: #ff6a00;

      &::before {
        content: "";
        position: absolute;
        width: 34px;
        height: 3px;
        background: #ff6a00;
        bottom: 4px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
.more {
  width: 165px;
  height: 50px;
  background: #fff;
  border-radius: 0 0 0 0;
  opacity: 1;
  border: 1px solid #ff6a00;
  line-height: 50px;
  font-size: 18px;
  color: #ff6a00;
  margin: 40px auto auto;
  cursor: pointer;
  text-align: center;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
}
</style>
